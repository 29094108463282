// src/components/AboutUs.tsx
import React, { useState, useEffect } from 'react';

interface People {
    image: string;
    title: string;
    description: string;
}


const AboutUs: React.FC = () => {
    const [people, setPeople] = useState<People[]>([]);
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        const fecthPeople = async () => {
            try{
                const response = await fetch('../data/aboutUs.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch aboutUs data');
                }
                const data: People[] = await response.json();
                setPeople(data);
            }catch (error) {
                console.error('Error fetching aboutUs data:', error);
            }
            setLoading(false);
        }
        fecthPeople();
    }, [])


    return (
         <section className="py-8 px-4 bg-gray-100">
            <h2 className="text-3xl font-semibold text-center mb-8">Meet Our Team</h2>
            {loading ? (
                <div className="text-center">Loading...</div>
            ) : (
                <div className="flex flex-wrap justify-center gap-8">
                    {people.map((person, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow-md p-6 w-60"
                        >
                            <h3 className="text-3xl font-bold text-gray-900">{person.name}</h3>
                            <img
                                src={person.image}
                                alt={person.title}
                                className="w-32 h-32 rounded-full object-cover mb-4"
                            />
                            <h3 className="text-m font-bold text-gray-900">{person.title}</h3>
                            <p className="text-gray-600 text-center mt-2">{person.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default AboutUs;
