// src/components/Welcome.tsx
import React from 'react';

const Welcome: React.FC = () => {
    return (
        <section className="bg-gray-800 text-white text-center py-5 rounded-lg">
            <h1 className="text-4xl font-bold">Welcome to Cuckco!</h1>
            <p className="text-lg mt-4">Where we cuck for your needs!</p>
        </section>
    );
};

export default Welcome;
