import React from 'react';
import icon from '../images/icon/icon-original.png'

const Header: React.FC = () => {
    return (
        <header className="bg-gray-800 sticky top-0 z-50 shadow-md p-4 flex items-center">
            {/* Icon */}
            <div className="flex items-center">
                <img 
                    src={icon}
                    alt="Cucko Logo" 
                    className="h-32 w-32 mr-3 rounded-lg"
                />
                <div>
                    <h1 className="text-4xl font-bold text-white">Cuckco</h1>
                    <b className="text-sm text-white absolute left-40">For the cuckhold enthusiast</b>
                </div>
            </div>
        </header>
    );
};

export default Header;
