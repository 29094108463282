import React from 'react'; 
import Welcome from '../components/Welcome.tsx'
import Carousel from '../components/Carousel.tsx'
import AboutUs from '../components/AboutUs.tsx'

const Home: React.FC = () => {
    return (
        <div className="flex flex-col items-center h-screen bg-gray-100 text-center space-y-6 px-4 py-10">
            {/* Outer container with background color */}
            <div className="w-full max-w-screen-lg mx-auto p-1">
                <Welcome />
            </div>
            <div className="bg- p-1 rounded-lg shadow-lg">
                <Carousel />
            </div>
            <div className="bg-white w-full max-w-screen-lg mx-auto p-1 rounded-lg shadow-lg">
                <AboutUs />
            </div>
        </div>
    );
};

export default Home;
