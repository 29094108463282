// src/components/Carousel.tsx
import React, { useState, useEffect } from 'react';

interface Chair {
    image: string;
    title: string;
    description: string;
}

const Carousel: React.FC = () => {
    const [chairs, setChairs] = useState<Chair[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchChairs = async () => {
            try {
                const response = await fetch('../data/chairs.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch chairs data');
                }
                const data: Chair[] = await response.json();
                setChairs(data);
            } catch (error) {
                console.error('Error fetching chair data:', error);
            }
            setLoading(false)
        };
        fetchChairs();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Render a loading indicator
    }

    if (chairs.length === 0) {
        return <div>No data available</div>; // Handle case where no chairs are available
    }

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % chairs.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + chairs.length) % chairs.length);
    };

    return (
        <section className="py-1">
            <div className="relative">
                <div className="bg-gray-800 absolute top-0 left-0 right-0 text-center text-white p-3">
                    <h2 className="text-2xl font-bold font-poppins">{chairs[currentIndex].title}</h2>
                </div>
                <img 
                    src={chairs[currentIndex].image}
                    alt={chairs[currentIndex].title}
                    className="w-full h-96 object-cover rounded-lg"
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-between items-center p-4">
                    <button onClick={prevSlide} className="bg-gray-800 text-white p-2 rounded-full">
                        &#8592;
                    </button>
                    <button onClick={nextSlide} className="bg-gray-800 text-white p-2 rounded-full">
                        &#8594;
                    </button>
                </div>
                <div className="bg-gray-800 bg-opacity-70 absolute bottom-0 left-0 right-0 text-center text-white p-1">
                    <p className="text-lg">{chairs[currentIndex].description}</p>
                </div>
            </div>
        </section>
    );
};

export default Carousel;
